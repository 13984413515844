import React, { useState, useEffect } from "react"

import { Row, Col, Input, Switch, Select, Space } from "antd"
import BulletPointTicket from "./components/BulletPointTick"
import couriers from "images/landing-page/couriers.png"
import dpd from "images/landing-page/dpd-bubble.png"
import postnl from "images/landing-page/postnl-bubble.png"
import fedex from "images/landing-page/fedex-bubble.png"
import ups from "images/landing-page/ups-bubble.png"
import gls from "images/landing-page/gls-bubble.png"
import dhl from "images/landing-page/dhl-bubble.png"
import omniva from "images/landing-page/omniva-bubble.png"
import tnt from "images/landing-page/tnt-bubble.png"
import siusk24 from "images/landing-page/siusk24.png"
import Aos from "aos"
import "aos/dist/aos.css"
import { useMediaQuery } from "react-responsive"

const bulletPoints = [
  "Siuntos į daugiau nei 200 šalių",
  "Pristatymas nuo durų iki durų",
  "Paštomatų paslaugos siunčiant siuntas į užsienį",
  "Pristatymas į užsienį nuo 2 d.d. iki 9 d.d. ",
]

const connectingPairs = [
  {
    courier1: "ups",
    courier2: "tnt",
  },
  {
    courier1: "ups",
    courier2: "gls",
  },
  {
    courier1: "gls",
    courier2: "dhl",
  },
  {
    courier1: "dhl",
    courier2: "fedex",
  },
  {
    courier1: "fedex",
    courier2: "postnl",
  },
  {
    courier1: "postnl",
    courier2: "omniva",
  },
  {
    courier1: "omniva",
    courier2: "dpd",
  },
  {
    courier1: "dpd",
    courier2: "tnt",
  },
  {
    courier1: "ups",
    courier2: "fedex",
  },
  {
    courier1: "gls",
    courier2: "tnt",
  },
  {
    courier1: "gls",
    courier2: "omniva",
  },
  {
    courier1: "gls",
    courier2: "postnl",
  },
  {
    courier1: "dhl",
    courier2: "dpd",
  },
  {
    courier1: "dhl",
    courier2: "omniva",
  },
  {
    courier1: "fedex",
    courier2: "dpd",
  },
  {
    courier1: "postnl",
    courier2: "dpd",
  },
]

const CourierSection = () => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 992px)" })

  useEffect(() => {
    setInterval(() => {
      connectingPairs.map((pair, index) => {
        const upsElement = document.getElementById(pair.courier1)
        const tntElement = document.getElementById(pair.courier2)
        const playArea = document.getElementById("play-area")
        const line = document.getElementById(
          `${pair.courier1}-${pair.courier2}`
        )

        if (line) {
          line.x1.baseVal.value =
            upsElement.getBoundingClientRect().x -
            playArea.getBoundingClientRect().x +
            upsElement.getBoundingClientRect().width / 2

          line.y1.baseVal.value =
            upsElement.getBoundingClientRect().y -
            playArea.getBoundingClientRect().y +
            upsElement.getBoundingClientRect().height / 2

          line.x2.baseVal.value =
            tntElement.getBoundingClientRect().x -
            playArea.getBoundingClientRect().x +
            tntElement.getBoundingClientRect().width / 2
          line.y2.baseVal.value =
            tntElement.getBoundingClientRect().y -
            playArea.getBoundingClientRect().y +
            tntElement.getBoundingClientRect().height / 2
        }
      })
    }, 50)
  }, [])

  return (
    <>
      <Row
        gutter={{ lg: 16 }}
        align="middle"
        style={{
          marginTop: isMobileScreen ? "0px" : "-200px",
          marginBottom: isMobileScreen ? "0px" : "200px",
        }}
      >
        <Col span={24} lg={{ offset: 2, span: 10 }}>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              data-aos={isMobileScreen ? "" : "flip-left"}
              style={{
                maxWidth: 600,
              }}
            >
              <div className="section-title" style={{ marginTop: "75px" }}>
                {"Platus kurjerių tinklas visame pasaulyje"}
              </div>

              <div
                className="section-sub-title"
                style={{ marginTop: "32px", marginBottom: "5px" }}
              >
                {
                  "Patikimiausių ir žinomiausių kurjerių kompanijų paslaugos vienoje vietoje"
                }
              </div>

              {bulletPoints.map((point, index) => (
                <div className="section-bullet-point" key={index}>
                  <Space size="small" style={{ alignItems: "center" }}>
                    <BulletPointTicket />
                    <div>{point}</div>
                  </Space>
                </div>
              ))}
            </div>
          </div>
        </Col>

        <Col span={24} lg={10}>
          <div
            style={{
              height: !isMobileScreen && 600,
              marginRight: !isMobileScreen && 30,
              marginTop: isMobileScreen ? 50 : 120,
              paddingTop: isMobileScreen ? 0 : 80,
            }}
          >
            {!isMobileScreen ? (
              <div
                id="play-area"
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  left: 50,
                }}
                data-aos={isMobileScreen ? "" : "fade-left"}
              >
                <svg id="connecting-lines">
                  {connectingPairs.map((pair, index) => (
                    <line
                      key={index}
                      className="connecting-line"
                      style={{
                        strokeWidth: "2px",
                        stroke: "rgba(40, 85, 120, 0.08)",
                      }}
                      id={`${pair.courier1}-${pair.courier2}`}
                    />
                  ))}
                </svg>

                <div style={{ position: "absolute" }}>
                  <img src={siusk24} style={{ width: 420 }} />
                </div>

                <div
                  id="ups"
                  className="motion-demo"
                  style={{ animationDelay: "-64s", top: 20, left: 150 }}
                >
                  <img src={ups} style={{ width: 134 }} />
                </div>

                <div
                  id="gls"
                  className="motion-demo"
                  style={{ animationDelay: "-56s", top: 70, left: 300 }}
                >
                  <img src={gls} style={{ width: 114 }} />
                </div>

                <div
                  id="dhl"
                  className="motion-demo"
                  style={{ animationDelay: "-48s", top: 200, left: 350 }}
                >
                  <img src={dhl} style={{ width: 104 }} />
                </div>

                <div
                  id="fedex"
                  className="motion-demo"
                  style={{ animationDelay: "-40s", top: 300, left: 300 }}
                >
                  <img src={fedex} style={{ width: 114 }} />
                </div>

                <div
                  id="postnl"
                  className="motion-demo"
                  style={{ animationDelay: "-32s", top: 400, left: 250 }}
                >
                  <img src={postnl} style={{ width: 134 }} />
                </div>

                <div
                  id="omniva"
                  className="motion-demo"
                  style={{ animationDelay: "-24s", top: 310, left: 100 }}
                >
                  <img src={omniva} style={{ width: 114 }} />
                </div>

                <div
                  id="dpd"
                  className="motion-demo"
                  style={{ animationDelay: "-16s", top: 220, left: 0 }}
                >
                  <img src={dpd} style={{ width: 114 }} />
                </div>

                <div
                  id="tnt"
                  className="motion-demo"
                  style={{ animationDelay: "-8s", top: 90, left: 40 }}
                >
                  <img src={tnt} style={{ width: 104 }} />
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={couriers} style={{ width: "100%", maxWidth: 600 }} />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default CourierSection
