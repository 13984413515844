export const COUNTRY_CODES = [
  {
    id: 1,
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  {
    id: 2,
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
  },
  {
    id: 3,
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    id: 4,
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    id: 5,
    name: "AmericanSamoa",
    dial_code: "+1 684",
    code: "AS",
  },
  {
    id: 6,
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    id: 7,
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    id: 8,
    name: "Anguilla",
    dial_code: "+1 264",
    code: "AI",
  },
  {
    id: 9,
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
  },
  {
    id: 10,
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    id: 11,
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    id: 12,
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    id: 13,
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    id: 14,
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    id: 15,
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    id: 16,
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    id: 17,
    name: "Bahamas",
    dial_code: "+1 242",
    code: "BS",
  },
  {
    id: 18,
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    id: 19,
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    id: 20,
    name: "Barbados",
    dial_code: "+1 246",
    code: "BB",
  },
  {
    id: 21,
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    id: 22,
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    id: 23,
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    id: 24,
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    id: 25,
    name: "Bermuda",
    dial_code: "+1 441",
    code: "BM",
  },
  {
    id: 26,
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    id: 27,
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
  },
  {
    id: 28,
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    id: 29,
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    id: 30,
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    id: 31,
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    id: 32,
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    id: 33,
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    id: 34,
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    id: 35,
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    id: 36,
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    id: 37,
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  {
    id: 38,
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  },
  {
    id: 39,
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    id: 40,
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    id: 41,
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    id: 42,
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    id: 43,
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    id: 44,
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    id: 45,
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
  },
  {
    id: 46,
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
  },
  {
    id: 47,
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    id: 48,
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    id: 49,
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    id: 50,
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
  },
  {
    id: 51,
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    id: 52,
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    id: 53,
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    id: 54,
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    id: 55,
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    id: 56,
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
  },
  {
    id: 57,
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    id: 58,
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    id: 59,
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    id: 60,
    name: "Dominica",
    dial_code: "+1 767",
    code: "DM",
  },
  {
    id: 61,
    name: "Dominican Republic",
    dial_code: "+1 849",
    code: "DO",
  },
  {
    id: 62,
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    id: 63,
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    id: 64,
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    id: 65,
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    id: 66,
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    id: 67,
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    id: 68,
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    id: 69,
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
  },
  {
    id: 70,
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    id: 71,
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    id: 72,
    name: "Finland",
    dial_code: "+358",
    code: "FI",
  },
  {
    id: 73,
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    id: 74,
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    id: 75,
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    id: 76,
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    id: 77,
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    id: 78,
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    id: 79,
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    id: 80,
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    id: 81,
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    id: 82,
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    id: 83,
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    id: 84,
    name: "Grenada",
    dial_code: "+1 473",
    code: "GD",
  },
  {
    id: 85,
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    id: 86,
    name: "Guam",
    dial_code: "+1 671",
    code: "GU",
  },
  {
    id: 87,
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    id: 88,
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    id: 89,
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    id: 90,
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  {
    id: 91,
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    id: 92,
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    id: 93,
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    id: 94,
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    id: 95,
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    id: 96,
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    id: 97,
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    id: 98,
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    id: 99,
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    id: 100,
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
  },
  {
    id: 101,
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    id: 102,
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  {
    id: 103,
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    id: 104,
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    id: 105,
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    id: 106,
    name: "Jamaica",
    dial_code: "+1 876",
    code: "JM",
  },
  {
    id: 107,
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  {
    id: 108,
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    id: 109,
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    id: 110,
    name: "Kazakhstan",
    dial_code: "+7 7",
    code: "KZ",
  },
  {
    id: 111,
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    id: 112,
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    id: 113,
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
  },
  {
    id: 114,
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
  },
  {
    id: 115,
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    id: 116,
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    id: 117,
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    id: 118,
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    id: 119,
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    id: 120,
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    id: 121,
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    id: 122,
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    id: 123,
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    id: 124,
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    id: 125,
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    id: 126,
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    id: 127,
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    id: 128,
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    id: 129,
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    id: 130,
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    id: 131,
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    id: 132,
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    id: 133,
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    id: 134,
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    id: 135,
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    id: 136,
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    id: 137,
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  {
    id: 138,
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    id: 139,
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    id: 140,
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
  },
  {
    id: 141,
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
  },
  {
    id: 142,
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    id: 143,
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    id: 144,
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    id: 145,
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    id: 146,
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    id: 147,
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    id: 148,
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    id: 149,
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    id: 150,
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    id: 151,
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    id: 152,
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    id: 153,
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    id: 154,
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    id: 155,
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    id: 156,
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    id: 157,
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    id: 158,
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    id: 159,
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    id: 160,
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
  },
  {
    id: 161,
    name: "Northern Mariana Islands",
    dial_code: "+1 670",
    code: "MP",
  },
  {
    id: 162,
    name: "Norway",
    dial_code: "+47",
    code: "NO",
  },
  {
    id: 163,
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    id: 164,
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    id: 165,
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    id: 166,
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    id: 167,
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    id: 168,
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    id: 169,
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    id: 170,
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    id: 171,
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    id: 172,
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    id: 173,
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    id: 174,
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    id: 175,
    name: "Puerto Rico",
    dial_code: "+1 939",
    code: "PR",
  },
  {
    id: 176,
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    id: 177,
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    id: 178,
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    id: 179,
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    id: 180,
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    id: 181,
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
  },
  {
    id: 182,
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  {
    id: 183,
    name: "Saint Kitts and Nevis",
    dial_code: "+1 869",
    code: "KN",
  },
  {
    id: 184,
    name: "Saint Lucia",
    dial_code: "+1 758",
    code: "LC",
  },
  {
    id: 185,
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
  },
  {
    id: 186,
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    id: 187,
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1 784",
    code: "VC",
  },
  {
    id: 188,
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    id: 189,
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    id: 190,
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    id: 191,
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    id: 192,
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    id: 193,
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    id: 194,
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    id: 195,
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    id: 196,
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    id: 197,
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    id: 198,
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    id: 199,
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    id: 200,
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    id: 201,
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    id: 202,
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  {
    id: 203,
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    id: 204,
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    id: 205,
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    id: 206,
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    id: 207,
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    id: 208,
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    id: 209,
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    id: 210,
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    id: 211,
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    id: 212,
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
  },
  {
    id: 213,
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    id: 214,
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    id: 215,
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    id: 216,
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    id: 217,
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    id: 218,
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    id: 219,
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    id: 220,
    name: "Trinidad and Tobago",
    dial_code: "+1 868",
    code: "TT",
  },
  {
    id: 221,
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    id: 222,
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    id: 223,
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    id: 224,
    name: "Turks and Caicos Islands",
    dial_code: "+1 649",
    code: "TC",
  },
  {
    id: 225,
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    id: 226,
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    id: 227,
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    id: 228,
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    id: 229,
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    id: 230,
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    id: 231,
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    id: 232,
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    id: 233,
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    id: 234,
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    id: 235,
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    id: 236,
    name: "Virgin Islands, British",
    dial_code: "+1 284",
    code: "VG",
  },
  {
    id: 237,
    name: "Virgin Islands, U.S.",
    dial_code: "+1 340",
    code: "VI",
  },
  {
    id: 238,
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    id: 239,
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    id: 240,
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    id: 241,
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
]

export const POSTCODE_REGEXES = {
  GB: /^GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|BX|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}$/,
  JE: /^JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
  GG: /^GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
  IM: /^IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
  US: /^\d{5}([ \-]\d{4})?$/,
  CA: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/,
  DE: /^\d{5}$/,
  JP: /^\d{3}-\d{4}$/,
  FR: /^\d{2}[ ]?\d{3}$/,
  AU: /^\d{4}$/,
  IT: /^\d{5}$/,
  CH: /^\d{4}$/,
  AT: /^\d{4}$/,
  ES: /^\d{5}$/,
  NL: /^\d{4}[ ]?[A-Z]{2}$/,
  BE: /^\d{4}$/,
  DK: /^\d{4}$/,
  SE: /^\d{3}[ ]?\d{2}$/,
  NO: /^\d{4}$/,
  BR: /^\d{5}[\-]?\d{3}$/,
  PT: /^\d{4}([\-]\d{3})?$/,
  FI: /^\d{5}$/,
  AX: /^22\d{3}$/,
  KR: /^\d{5}$/,
  CN: /^\d{6}$/,
  TW: /^\d{3}(\d{2})?$/,
  SG: /^\d{6}$/,
  DZ: /^\d{5}$/,
  AD: /^AD\d{3}$/,
  AR: /^([A-HJ-NP-Z])?\d{4}([A-Z]{3})?$/,
  AM: /^(37)?\d{4}$/,
  AZ: /^AZ\d{4}$/,
  BH: /^((1[0-2]|[2-9])\d{2})?$/,
  BD: /^\d{4}$/,
  BB: /^(BB\d{5})?$/,
  BY: /^\d{6}$/,
  BM: /^[A-Z]{2}[ ]?[A-Z0-9]{2}$/,
  BA: /^\d{5}$/,
  IO: /^BBND 1ZZ$/,
  BN: /^[A-Z]{2}[ ]?\d{4}$/,
  BG: /^\d{4}$/,
  KH: /^(\d{5}|\d{6})$/,
  CV: /^\d{4}$/,
  CL: /^\d{7}$/,
  CR: /^\d{4,5}|\d{3}-\d{4}$/,
  HR: /^\d{5}$/,
  CY: /^\d{4}$/,
  CZ: /^\d{3}[ ]?\d{2}$/,
  DO: /^\d{5}$/,
  EC: /^([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?$/,
  EG: /^\d{5}$/,
  EE: /^\d{5}$/,
  FO: /^\d{3}$/,
  GE: /^\d{4}$/,
  GR: /^\d{3}[ ]?\d{2}$/,
  GL: /^39\d{2}$/,
  GT: /^\d{5}$/,
  HT: /^\d{4}$/,
  HN: /^(?:\d{5})?$/,
  HU: /^\d{4}$/,
  IS: /^\d{3}$/,
  IN: /^\d{6}$/,
  ID: /^\d{5}$/,
  IL: /^(\d{5}|\d{7})$/,
  JO: /^\d{5}$/,
  KZ: /^\d{6}$/,
  KE: /^\d{5}$/,
  KW: /^\d{5}$/,
  LA: /^\d{5}$/,
  LV: /^\d{4}$/,
  LB: /^(\d{4}([ ]?\d{4})?)?$/,
  LI: /^(948[5-9])|(949[0-7])$/,
  LT: /^\d{5}$/,
  LU: /^\d{4}$/,
  MK: /^\d{4}$/,
  MY: /^\d{5}$/,
  MV: /^\d{5}$/,
  MT: /^[A-Z]{3}[ ]?\d{2,4}$/,
  MU: /^\d{5}$/,
  MX: /^\d{5}$/,
  MD: /^\d{4}$/,
  MC: /^980\d{2}$/,
  MA: /^\d{5}$/,
  NP: /^\d{5}$/,
  NZ: /^\d{4}$/,
  NI: /^((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?$/,
  NG: /^(\d{6})?$/,
  OM: /^(PC )?\d{3}$/,
  PK: /^\d{5}$/,
  PY: /^\d{4}$/,
  PH: /^\d{4}$/,
  PL: /^\d{2}-\d{3}$/,
  PR: /^00[679]\d{2}([ \-]\d{4})?$/,
  RO: /^\d{6}$/,
  RU: /^\d{6}$/,
  SM: /^4789\d$/,
  SA: /^\d{5}$/,
  SN: /^\d{5}$/,
  SK: /^\d{3}[ ]?\d{2}$/,
  SI: /^\d{4}$/,
  ZA: /^\d{4}$/,
  LK: /^\d{5}$/,
  TJ: /^\d{6}$/,
  TH: /^\d{5}$/,
  TN: /^\d{4}$/,
  TR: /^\d{5}$/,
  TM: /^\d{6}$/,
  UA: /^\d{5}$/,
  UY: /^\d{5}$/,
  UZ: /^\d{6}$/,
  VA: /^00120$/,
  VE: /^\d{4}$/,
  ZM: /^\d{5}$/,
  AS: /^96799$/,
  CC: /^6799$/,
  CK: /^\d{4}$/,
  RS: /^\d{5}$/,
  ME: /^8\d{4}$/,
  CS: /^\d{5}$/,
  YU: /^\d{5}$/,
  CX: /^6798$/,
  ET: /^\d{4}$/,
  FK: /^FIQQ 1ZZ$/,
  NF: /^2899$/,
  FM: /^(9694[1-4])([ \-]\d{4})?$/,
  GF: /^9[78]3\d{2}$/,
  GN: /^\d{3}$/,
  GP: /^9[78][01]\d{2}$/,
  GS: /^SIQQ 1ZZ$/,
  GU: /^969[123]\d([ \-]\d{4})?$/,
  GW: /^\d{4}$/,
  HM: /^\d{4}$/,
  IQ: /^\d{5}$/,
  KG: /^\d{6}$/,
  LR: /^\d{4}$/,
  LS: /^\d{3}$/,
  MG: /^\d{3}$/,
  MH: /^969[67]\d([ \-]\d{4})?$/,
  MN: /^\d{6}$/,
  MP: /^9695[012]([ \-]\d{4})?$/,
  MQ: /^9[78]2\d{2}$/,
  NC: /^988\d{2}$/,
  NE: /^\d{4}$/,
  VI: /^008(([0-4]\d)|(5[01]))([ \-]\d{4})?$/,
  PF: /^987\d{2}$/,
  PG: /^\d{3}$/,
  PM: /^9[78]5\d{2}$/,
  PN: /^PCRN 1ZZ$/,
  PW: /^96940$/,
  RE: /^9[78]4\d{2}$/,
  SH: /^(ASCN|STHL) 1ZZ$/,
  SJ: /^\d{4}$/,
  SO: /^\d{5}$/,
  SZ: /^[HLMS]\d{3}$/,
  TC: /^TKCA 1ZZ$/,
  WF: /^986\d{2}$/,
  XK: /^\d{5}$/,
  YT: /^976\d{2}$/,
}

// export const POSTCODE_REGEXES = [
//   {
//     country_code: "UK",
//     regex:
//       /^([A-Z]){1}([0-9][0-9]|[0-9]|[A-Z][0-9][A-Z]|[A-Z][0-9][0-9]|[A-Z][0-9]|[0-9][A-Z]){1}([ ])?([0-9][A-z][A-z]){1}$/i,
//   },
//   {
//     country_code: "GB",
//     regex:
//       /^([A-Z]){1}([0-9][0-9]|[0-9]|[A-Z][0-9][A-Z]|[A-Z][0-9][0-9]|[A-Z][0-9]|[0-9][A-Z]){1}([ ])?([0-9][A-z][A-z]){1}$/i,
//   },
//   {
//     country_code: "JE",
//     regex: /^JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
//   },
//   {
//     country_code: "GG",
//     regex: /^GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
//   },
//   {
//     country_code: "IM",
//     regex: /^IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
//   },
//   {
//     country_code: "US",
//     regex: /^([0-9]{5})(?:-([0-9]{4}))?$/,
//   },
//   {
//     country_code: "CA",
//     regex:
//       /^([ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ])\s*([0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])$/i,
//   },
//   {
//     country_code: "IE",
//     regex: /^([AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/,
//   },
//   {
//     country_code: "DE",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "JP",
//     regex: /^\d{3}-\d{4}$/,
//   },
//   {
//     country_code: "FR",
//     regex: /^\d{2}[ ]?\d{3}$/,
//   },
//   {
//     country_code: "AU",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "IT",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "CH",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "AT",
//     regex: /^(?!0)\d{4}$/,
//   },
//   {
//     country_code: "ES",
//     regex: /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/,
//   },
//   {
//     country_code: "NL",
//     regex: /^\d{4}[ ]?[A-Z]{2}$/,
//   },
//   {
//     country_code: "BE",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "DK",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "SE",
//     regex: /^(SE-)?\d{3}[ ]?\d{2}$/,
//   },
//   {
//     country_code: "NO",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "BR",
//     regex: /^\d{5}[\-]?\d{3}$/,
//   },
//   {
//     country_code: "PT",
//     regex: /^\d{4}([\-]\d{3})?$/,
//   },
//   {
//     country_code: "FI",
//     regex: /^(FI-|AX-)?\d{5}$/,
//   },
//   {
//     country_code: "AX",
//     regex: /^22\d{3}$/,
//   },
//   {
//     country_code: "KR",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "CN",
//     regex: /^\d{6}$/,
//   },
//   {
//     country_code: "TW",
//     regex: /^\d{3}(\d{2})?$/,
//   },
//   {
//     country_code: "SG",
//     regex: /^\d{6}$/,
//   },
//   {
//     country_code: "DZ",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "AD",
//     regex: /^AD\d{3}$/,
//   },
//   {
//     country_code: "AR",
//     regex: /^([A-HJ-NP-Z])?\d{4}([A-Z]{3})?$/,
//   },
//   {
//     country_code: "AM",
//     regex: /^(37)?\d{4}$/,
//   },
//   {
//     country_code: "AZ",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "BH",
//     regex: /^((1[0-2]|[2-9])\d{2})?$/,
//   },
//   {
//     country_code: "BD",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "BB",
//     regex: /^(BB\d{5})?$/,
//   },
//   {
//     country_code: "BY",
//     regex: /^\d{6}$/,
//   },
//   {
//     country_code: "BM",
//     regex: /^[A-Z]{2}[ ]?[A-Z0-9]{2}$/,
//   },
//   {
//     country_code: "BA",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "IO",
//     regex: /^BBND 1ZZ$/,
//   },
//   {
//     country_code: "BN",
//     regex: /^[A-Z]{2}[ ]?\d{4}$/,
//   },
//   {
//     country_code: "BG",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "KH",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "CV",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "CL",
//     regex: /^\d{7}$/,
//   },
//   {
//     country_code: "CR",
//     regex: /^(\d{4,5}|\d{3}-\d{4})$/,
//   },
//   {
//     country_code: "HR",
//     regex: /^(HR-)?\d{5}$/,
//   },
//   {
//     country_code: "CY",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "CZ",
//     regex: /^\d{3}[ ]?\d{2}$/,
//   },
//   {
//     country_code: "DO",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "EC",
//     regex: /^([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?$/,
//   },
//   {
//     country_code: "EG",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "EE",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "FO",
//     regex: /^\d{3}$/,
//   },
//   {
//     country_code: "GE",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "GR",
//     regex: /^\d{3}[ ]?\d{2}$/,
//   },
//   {
//     country_code: "GL",
//     regex: /^39\d{2}$/,
//   },
//   {
//     country_code: "GT",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "HT",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "HN",
//     regex: /^(?:\d{5})?$/,
//   },
//   {
//     country_code: "HU",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "IS",
//     regex: /^\d{3}$/,
//   },
//   {
//     country_code: "IN",
//     regex: /^\d{6}$/,
//   },
//   {
//     country_code: "ID",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "IL",
//     regex: /^\d{5,7}$/,
//   },
//   {
//     country_code: "JO",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "KZ",
//     regex: /^\d{6}$/,
//   },
//   {
//     country_code: "KE",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "KW",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "LA",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "LV",
//     regex: /^(LV-)?\d{4}$/,
//   },
//   {
//     country_code: "LB",
//     regex: /^(\d{4}([ ]?\d{4})?)?$/,
//   },
//   {
//     country_code: "LI",
//     regex: /^(948[5-9])|(949[0-7])$/,
//   },
//   {
//     country_code: "LT",
//     regex: /^(LT-)?\d{5}$/,
//   },
//   {
//     country_code: "LU",
//     regex: /^(L-)?\d{4}$/,
//   },
//   {
//     country_code: "MK",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "MY",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "MV",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "MT",
//     regex: /^[A-Z]{3}[ ]?\d{2,4}$/,
//   },
//   {
//     country_code: "MU",
//     regex: /^((\d|[A-Z])\d{4})?$/,
//   },
//   {
//     country_code: "MX",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "MD",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "MC",
//     regex: /^980\d{2}$/,
//   },
//   {
//     country_code: "MA",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "NP",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "NZ",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "NI",
//     regex: /^((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?$/,
//   },
//   {
//     country_code: "NG",
//     regex: /^(\d{6})?$/,
//   },
//   {
//     country_code: "OM",
//     regex: /^(PC )?\d{3}$/,
//   },
//   {
//     country_code: "PK",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "PY",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "PH",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "PL",
//     regex: /^\d{2}-\d{3}$/,
//   },
//   {
//     country_code: "PR",
//     regex: /^00[679]\d{2}([ \-]\d{4})?$/,
//   },
//   {
//     country_code: "RO",
//     regex: /^\d{6}$/,
//   },
//   {
//     country_code: "RU",
//     regex: /^\d{6}$/,
//   },
//   {
//     country_code: "SM",
//     regex: /^4789\d$/,
//   },
//   {
//     country_code: "SA",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "SN",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "SK",
//     regex: /^\d{3}[ ]?\d{2}$/,
//   },
//   {
//     country_code: "SI",
//     regex: /^(SI-)?\d{4}$/,
//   },
//   {
//     country_code: "ZA",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "LK",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "TJ",
//     regex: /^\d{6}$/,
//   },
//   {
//     country_code: "TH",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "TN",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "TR",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "TM",
//     regex: /^\d{6}$/,
//   },
//   {
//     country_code: "UA",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "UY",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "UZ",
//     regex: /^\d{6}$/,
//   },
//   {
//     country_code: "VA",
//     regex: /^00120$/,
//   },
//   {
//     country_code: "VE",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "ZM",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "AS",
//     regex: /^96799$/,
//   },
//   {
//     country_code: "CC",
//     regex: /^6799$/,
//   },
//   {
//     country_code: "CK",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "RS",
//     regex: /^\d{5,6}$/,
//   },
//   {
//     country_code: "ME",
//     regex: /^8\d{4}$/,
//   },
//   {
//     country_code: "CS",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "YU",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "CX",
//     regex: /^6798$/,
//   },
//   {
//     country_code: "ET",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "FK",
//     regex: /^FIQQ 1ZZ$/,
//   },
//   {
//     country_code: "NF",
//     regex: /^2899$/,
//   },
//   {
//     country_code: "FM",
//     regex: /^(9694[1-4])([ \-]\d{4})?$/,
//   },
//   {
//     country_code: "GF",
//     regex: /^9[78]3\d{2}$/,
//   },
//   {
//     country_code: "GN",
//     regex: /^\d{3}$/,
//   },
//   {
//     country_code: "GP",
//     regex: /^9[78][01]\d{2}$/,
//   },
//   {
//     country_code: "GS",
//     regex: /^SIQQ 1ZZ$/,
//   },
//   {
//     country_code: "GU",
//     regex: /^969[123]\d([ \-]\d{4})?$/,
//   },
//   {
//     country_code: "GW",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "HM",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "IQ",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "KG",
//     regex: /^\d{6}$/,
//   },
//   {
//     country_code: "LR",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "LS",
//     regex: /^\d{3}$/,
//   },
//   {
//     country_code: "MG",
//     regex: /^\d{3}$/,
//   },
//   {
//     country_code: "MH",
//     regex: /^969[67]\d([ \-]\d{4})?$/,
//   },
//   {
//     country_code: "MN",
//     regex: /^\d{6}$/,
//   },
//   {
//     country_code: "MP",
//     regex: /^9695[012]([ \-]\d{4})?$/,
//   },
//   {
//     country_code: "MQ",
//     regex: /^9[78]2\d{2}$/,
//   },
//   {
//     country_code: "NC",
//     regex: /^988\d{2}$/,
//   },
//   {
//     country_code: "NE",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "VI",
//     regex: /^008(([0-4]\d)|(5[01]))([ \-]\d{4})?$/,
//   },
//   {
//     country_code: "PF",
//     regex: /^987\d{2}$/,
//   },
//   {
//     country_code: "PG",
//     regex: /^\d{3}$/,
//   },
//   {
//     country_code: "PM",
//     regex: /^9[78]5\d{2}$/,
//   },
//   {
//     country_code: "PN",
//     regex: /^PCRN 1ZZ$/,
//   },
//   {
//     country_code: "PW",
//     regex: /^96940$/,
//   },
//   {
//     country_code: "RE",
//     regex: /^9[78]4\d{2}$/,
//   },
//   {
//     country_code: "SH",
//     regex: /^(ASCN|STHL) 1ZZ$/,
//   },
//   {
//     country_code: "SJ",
//     regex: /^\d{4}$/,
//   },
//   {
//     country_code: "SO",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "SZ",
//     regex: /^[HLMS]\d{3}$/,
//   },
//   {
//     country_code: "TC",
//     regex: /^TKCA 1ZZ$/,
//   },
//   {
//     country_code: "WF",
//     regex: /^986\d{2}$/,
//   },
//   {
//     country_code: "XK",
//     regex: /^\d{5}$/,
//   },
//   {
//     country_code: "YT",
//     regex: /^976\d{2}$/,
//   },
//   {
//     country_code: "INTL",
//     regex: /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/i,
//   },
// ]
