import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import MainWrapper from "../../helpers/MainWrapper"
import MainHeader from "./MainHeader"
import CourierSection from "./CourierSection"
import "./styles.less"
import SystemSection from "./SystemSection"
import ServiceSection from "./ServiceSection"
import IntegrationSection from "./IntegrationSection"
import BusinessForm from "./BusinessForm"
import Request from "../../helpers/Request"
import type { Country, State } from "../../components/types/formTypes"
import { COUNTRY_FLAGS } from "../../helpers/countryIcons"
import Aos from "aos"

declare var root: any

type Countries = Array<Country>

const LandingPage = () => {
  const [countries, setCountries] = useState<Countries>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    Request("/countries/search?query=", "GET").then((response) => {
      console.log('test')
      setCountries(response)
    })
    Aos.init({ once: true, duration: 1500 })
  }, [])

  useEffect(() => {
    if (countries && countries.length > 0) {
      setLoading(false)
    }
  })

  return (
    <>
      {!loading && (
        <MainWrapper menus={true}>
          <MainHeader countries={countries} COUNTRY_FLAGS={COUNTRY_FLAGS} />
          <div style={{ margin: "20px" }}>
            <CourierSection />
            <SystemSection />
            <ServiceSection />
            <IntegrationSection />
          </div>
          <BusinessForm />
        </MainWrapper>
      )}
    </>
  )
}

if (root) {
  ReactDOM.render(<LandingPage />, root)
}
