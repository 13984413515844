import React, { useState, useEffect } from "react";
import Request from "../../helpers/Request";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const HeroCarousel = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    Request("/hero_carousel_images", "GET")
      .then((response) => {
        if (response.images) setImages(response.images)
      })
      .catch(({ errors }) => {
        console.log("errors from catch: ", errors)
      })
  }, [])

  const carouselOptions = {
    showIndicators: false,
    showThumbs: false,
    showStatus: false,
    infiniteLoop: true,
    autoPlay: true,
    inverval: 8000
  };


  return (
    <>
      {images.length > 0 && (
        <div className="hero-carousel">
          <Carousel {...carouselOptions}>
            {images.map((image, index) => (
              <div key={index} onClick={() => window.open(image.url)}>
                <img src={image.asset.url} alt={image.alt} />
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
}

export default HeroCarousel;
