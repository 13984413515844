import React, { useState, useEffect } from "react"
import { Modal, Space } from "antd"

const SuccessModal = ({ setIsSuccessModalVisible }) => {
  return (
    <>
      <Modal
        visible
        footer={false}
        bodyStyle={{ padding: 0 }}
        onCancel={() => setIsSuccessModalVisible(false)}
        maskClosable={false}
      >
        <div style={{ backgroundColor: "#F89823", paddingLeft: 44 }}>
          <div style={{ padding: 24 }}>
            <Space size="large">
              <img
                style={{ width: 60 }}
                src="https://assets.siusk24.lt/business-page/clarity_success-standard-line.svg"
              />
              <div className="header-title-3" style={{ color: "white" }}>
                {"Dėkojame!"}
              </div>
            </Space>
          </div>
        </div>
        <div style={{ padding: 24, paddingLeft: 64 }}>
          <div className="header-title-3">{"Gavome Jūsų žinutę."}</div>
          <div
            className="header-title-4"
            style={{ fontSize: 26, marginTop: 10 }}
          >
            {"Su Jumis susisieksime per 1 d.d. "}
          </div>
          <div style={{ marginTop: 30 }}>
            <button
              className="get-the-deal"
              onClick={() => setIsSuccessModalVisible(false)}
            >
              {"Tęsti"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SuccessModal
