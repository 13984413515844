import React, { useState, useEffect } from "react"

import { Row, Col, Input, Switch, Select, Space } from "antd"
import BulletPointTicket from "./components/BulletPointTick"
import mac from "images/landing-page/mac-image.png"
import Aos from "aos"
import "aos/dist/aos.css"
import { useMediaQuery } from "react-responsive"

const bulletPoints = [
  "Sistema veikia 24/7",
  "Visi užsakymai, sąskaitos ir siuntimų ataskaitos vienoje vietoje",
  "Išsamus siuntų sekimas",
  "Naudojimasis užsakymo valdymo sistema- nemokamas",
]

const ServiceSection = () => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 992px)" })

  return (
    <>
      <Row
        style={{ marginTop: isMobileScreen ? "80px" : "247px" }}
        gutter={{ lg: 16 }}
        align="middle"
      >
        <Col span={24} lg={{ offset: 2, span: 10 }}>
          <div
            data-aos={isMobileScreen ? "" : "flip-left"}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ maxWidth: 600 }}>
              <div className="section-title" style={{ marginTop: "0px" }}>
                {"Patogi užsakymų valdymo sistema"}
              </div>

              <div
                className="section-sub-title"
                style={{ marginTop: "32px", marginBottom: "5px" }}
              >
                {"Lengvas užsakymų valdymas vos kelių mygtukų paspaudimu"}
              </div>

              {bulletPoints.map((point, index) => (
                <div className="section-bullet-point" key={index}>
                  <Space size="small" style={{ alignItems: "center" }}>
                    <BulletPointTicket />
                    <div>{point}</div>
                  </Space>
                </div>
              ))}
            </div>
          </div>
        </Col>

        <Col span={24} lg={10}>
          <div
            style={{
              display: "flex",
              justifyContent: isMobileScreen ? "center" : "start",
            }}
          >
            <div data-aos={isMobileScreen ? "" : "fade-left"}>
              <img
                className="translate-image"
                src="../imgs/langs/lt_laptop.png"
                style={{
                  width: "100%",
                  maxWidth: "555px",
                  marginTop: isMobileScreen ? 30 : 200,
                  marginLeft: isMobileScreen && "-8%",
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ServiceSection
