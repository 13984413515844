import { Promise } from "es6-promise"

type fetchData = {
  method: string
  headers: any
  body?: any
}

declare var AppInfo: any

const convertToNestedObject = (obj, keyPath, value) => {
  const lastKeyIndex = keyPath.length - 1
  for (var i = 0; i < lastKeyIndex; ++i) {
    const key = keyPath[i]
    if (!(key in obj)) {
      obj[key] = {}
    }
    obj = obj[key]
  }
  obj[keyPath[lastKeyIndex]] = value
}

const dedotizeErrors = (errors) => {
  let dedotizedErrors = {}

  for (let key in errors) {
    let params = key.split(".")
    let value = errors[key]

    if (value.constructor === Object) value = dedotizeErrors(value)

    if (params.length > 1) {
      params[0] = `${params[0]}_attributes` //this line can be removed if backend errors would be passed correctly
      convertToNestedObject(dedotizedErrors, params, value)
    } else {
      dedotizedErrors[params[0]] = value
    }
  }

  return dedotizedErrors
}

const Request = async (
  path: string,
  method: string,
  options?: any
): Promise<any> => {
  let headers: Object = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": AppInfo.State.authenticityToken,
    "Access-Control-Allow-Origin": "*",
  }

  let fetchData: fetchData = {
    method: method.toUpperCase(),
    headers: headers,
  }

  if (options?.body) {
    fetchData.body = JSON.stringify(options.body)
  }

  const response = await fetch(path, fetchData)

  if (
    (response.status >= 200 && response.status < 300) ||
    [401, 422].includes(response.status)
  ) {
    if (response.status == 204) {
      return Promise.resolve()
    }

    const responseJSON = await response.json()

    if (responseJSON.errors) {
      let fixedErrors = dedotizeErrors(responseJSON.errors)

      let fixedReponse = {
        ...response,
        errors: fixedErrors,
      }

      return Promise.reject({
        status: response.status,
        errors: fixedReponse.errors,
      })
    }

    return Promise.resolve(responseJSON)
  } else if (response.status >= 300 && response.status <= 308) {
    return Promise.reject(response)
  } else {
    if (response.status == 500) {
      console.log("message", "We're sorry, but something went wrong.")
    }

    return Promise.reject(response)
  }
}

export default Request
