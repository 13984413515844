import React, { useEffect } from "react"
import TopBar from "../helpers/TopBar"
import Footer from "../helpers/Footer"
import "antd/dist/antd.less"
import "./styles.less"

type Props = {
  children: any
  menus?: boolean
}

declare var ga: any

const MainWrapper = ({ children, menus }: Props) => {
  useEffect(() => {
    if (typeof ga == "function") {
      ga("set", "location", window.location.href)
      ga("send", "pageview")
    }
  }, [])

  return (
    <>
      {menus && <TopBar />}
      <div>{children}</div>
      {menus && <Footer />}
    </>
  )
}

export default MainWrapper
