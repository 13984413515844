import React, { useState, useEffect } from "react"
import { Row, Col, Input, Switch, Select, Space } from "antd"
import BulletPointTicket from "./components/BulletPointTick"
import offerBox from "images/landing-page/offer-box.png"
import Aos from "aos"
import "aos/dist/aos.css"
import { useMediaQuery } from "react-responsive"

const bulletPoints = [
  "Visos siuntimo kainos vienoje vietoje",
  "Specialūs pasiūlymai verslui",
  "Kuo daugiau siuntų- tuo mažesnės kainos",
  "Patogus atsiskaitymas užsakant gabenimo paslaugą",
]

const SystemSection = () => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 992px)" })

  return (
    <>
      <Row
        gutter={{ lg: 16 }}
        align="middle"
        style={{ marginTop: isMobileScreen && 40 }}
      >
        <Col order={1} span={24} lg={{ span: 10, offset: 2, order: 0 }}>
          <div
            style={{
              display: "flex",
              justifyContent: isMobileScreen ? "center" : "end",
            }}
          >
            <div data-aos={isMobileScreen ? "" : "fade-right"}>
              <img
                src="../imgs/langs/lt_pricing.png"
                className="translate-image"
                style={{
                  width: "100%",
                  maxWidth: "567px",
                  marginTop: isMobileScreen && 30,
                  marginRight: !isMobileScreen && 60,
                }}
              />
            </div>
          </div>
        </Col>

        <Col span={24} lg={{ span: 10 }}>
          <div
            data-aos={isMobileScreen ? "" : "flip-right"}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ maxWidth: 600 }}>
              <div className="section-title">{"Geros kainos!"}</div>

              <div
                className="section-sub-title"
                style={{ marginTop: "32px", marginBottom: "5px" }}
              >
                {
                  "Siunčiantiems siuntas nuolat- nuolaidos ir specialūs pasiūlymai"
                }
              </div>

              {bulletPoints.map((point, index) => (
                <div className="section-bullet-point" key={index}>
                  <Space size="small" style={{ alignItems: "center" }}>
                    <BulletPointTicket />
                    <div>{point}</div>
                  </Space>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default SystemSection
