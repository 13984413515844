import React, { useEffect, useState } from "react"

import {
  Affix,
  Button,
  Row,
  Col,
  Input,
  Space,
  Menu,
  Dropdown,
  Drawer,
  Badge,
  Collapse,
  Divider,
} from "antd"
import s24logo from "images/s24logo.png"
import Phone from "images/phone_desktop.svg"
import MobileToolbarPhone from "images/phone_mobile.svg"
import PhoneBurger from "images/phone_burger.svg"
import Request from "./Request"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"
import { MenuOutlined, MobileFilled } from "@ant-design/icons"

import {
  SettingFilled,
  LogoutOutlined,
  ShoppingCartOutlined,
  CloseOutlined,
  LoginOutlined,
} from "@ant-design/icons"

import Translate from "./translate"

const { Search } = Input
const { SubMenu } = Menu
const { Panel } = Collapse

const Callto = ({ phone, children }) => {
  return <a href={`tel:${phone}`}>{children}</a>;
};

const TopBar = () => {
  const [showSearch, setShowSearch] = useState(false)
  const [loading, setLoading] = useState(true)
  const [topBarData, setTopBarData] = useState()
  const [showDrawer, setShowDrawer] = useState(false)
  const [touched, setTouched] = useState(false)
  const [mobileExpand, setMobileExpand] = useState(false)
  const [parcelSearchValue, setParcelSearchValue] = useState("")

  const isMobileScreen = useMediaQuery({ query: "(max-width: 1200px)" })

  useEffect(() => {
    Request("/top_navigation", "GET").then((response) => {
      setTopBarData(response)
    })
  }, [])

  useEffect(() => {
    if (topBarData) setLoading(false)
  })

  return (
    <>
      {!loading && (
        <div>
          {!isMobileScreen ? (
            <div>
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  position: "fixed",
                  zIndex: showDrawer ? 100 : -100,
                }}
                onClick={(e) => {
                  setShowDrawer(false)
                  setTouched(true)
                }}
              ></div>

              <div
                className={classNames("drawer", {
                  "drawer-expand": showDrawer,
                  "drawer-collapse": !showDrawer && touched,
                })}
              >
                <Row>
                  <Col span={6}>
                    <div style={{ padding: "20px" }}>
                      <a
                        style={{
                          color: "#f89823",
                          fontSize: "18px",
                          fontWeight: "bold",
                          paddingLeft: "5px",
                          marginBottom: "5px",
                        }}
                        href="/kurjeriai"
                      >
                        {"Kurjeriai"}
                      </a>

                      {topBarData?.data.service_pages_couriers.map((item) => (
                        <a
                          key={item.link}
                          href={`/terms_couriers_pages/${item.link}`}
                          style={{ color: "3e3e3e !important" }}
                          className="drawer-link-style"
                        >
                          {item.title}
                        </a>
                      ))}
                    </div>
                  </Col>

                  <Col span={6}>
                    <div style={{ padding: "20px" }}>
                      <div
                        style={{
                          color: "#f89823",
                          fontSize: "18px",
                          fontWeight: "bold",
                          paddingLeft: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        {"Siuntų siuntimas"}
                      </div>
                      <a className="drawer-link-style" href="/siuntu_tarnybos_paslaugos">
                        {"Teikiamos paslaugos"}
                      </a>
                      {topBarData?.data.service_pages_parcel_sending.map(
                        (item) => (
                          <a
                            key={item.link}
                            href={`/terms_couriers_pages/${item.link}`}
                            className="drawer-link-style"
                          >
                            {item.title}
                          </a>
                        )
                      )}
                    </div>
                  </Col>

                  <Col span={6}>
                    <div style={{ padding: "20px" }}>
                      <div
                        style={{
                          color: "#f89823",
                          fontSize: "18px",
                          fontWeight: "bold",
                          paddingLeft: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        {"Pagalba"}
                      </div>

                      {topBarData?.data.service_pages_help.map((item) => (
                        <a
                          key={item.link}
                          href={`/terms_couriers_pages/${item.link}`}
                          className="drawer-link-style"
                        >
                          {item.title}
                        </a>
                      ))}
                      {/* <a href={"/verslui"} className="drawer-link-style">
                        {"Verslui"}
                      </a> */}
                    </div>
                  </Col>

                  <Col span={6}>
                    <div style={{ padding: "20px" }}>
                      <div
                        style={{
                          color: "#f89823",
                          fontSize: "18px",
                          fontWeight: "bold",
                          paddingLeft: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        {"Įrankiai"}
                      </div>
                      <a
                        style={{
                          color: "#f89823",
                          fontSize: "18px",
                          fontWeight: "bold",
                          paddingLeft: "5px",
                          marginBottom: "5px",
                        }}
                        href="/blogs"
                      >
                        {"Naujienos"}
                      </a>
                      {topBarData?.data.service_pages_tools.map((item) => (
                        <a
                          key={item.link}
                          href={`/terms_couriers_pages/${item.link}`}
                          className="drawer-link-style"
                        >
                          {item.title}
                        </a>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  zIndex: 10,
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              >
                <div
                  style={{
                    height: "69px",
                    background: "#FFFFFF",
                    borderBottom: "1px solid #EDEDED",
                    boxSizing: "border-box",
                    display: "flex",
                  }}
                >
                  <Row gutter={32} style={{ width: "100%" }} align="middle">
                    <Col offset={0} span={9} style={{ display: "flex", alignItems: "center", gap: 10 }}>
                      <div style={{ display: "flex", marginLeft: "20px" }}>
                        <a href="/">
                          <img
                            src={s24logo}
                            style={{ width: 175, marginRight: 20 }}
                          />
                        </a>

                        <div
                          style={{ position: "relative", marginTop: "-3px" }}
                        >
                          <Input
                            placeholder="Siuntų paieška"
                            size="large"
                            style={{
                              width: "100%",
                              backgroundColor: "#F4F6FA",
                              color: "#88A1B5",
                              border: "none",
                            }}
                            onPressEnter={(event) => {
                              window.location.href = `/tracking?tracking%5Btracking_number%5D=${event.target.value}`
                            }}
                            onChange={(event) => {
                              setParcelSearchValue(event.target.value)
                            }}
                          />

                          <div
                            style={{
                              position: "absolute",
                              top: 12,
                              right: 10,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.location.href = `/tracking?tracking%5Btracking_number%5D=${parcelSearchValue}`
                            }}
                          >
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.118 3.13675L8.13082 1.50306L6.06518 0.417374C6.00247 0.376475 5.92874 0.354645 5.85332 0.354645C5.7779 0.354645 5.70417 0.376475 5.64146 0.417374L5.02707 0.748248L0.122511 3.36422C0.072703 3.40495 0.0359336 3.45878 0.016581 3.51932C-0.00254534 3.58348 -0.00254534 3.65162 0.016581 3.71578V9.2269C-0.005527 9.29767 -0.005527 9.37327 0.016581 9.44404C0.0492122 9.51237 0.105473 9.56729 0.175476 9.59914L0.514452 9.80593L5.23893 12.608C5.30073 12.6409 5.36981 12.6587 5.4402 12.6597C5.51269 12.6629 5.58399 12.6409 5.64146 12.5977L6.05459 12.3495L6.62661 11.9876L6.28764 11.4189L5.77917 11.7291V6.92112L10.6096 4.03631V6.34209H11.2769V3.45728C11.2833 3.39475 11.2718 3.33174 11.2437 3.27518C11.2157 3.21862 11.1722 3.17072 11.118 3.13675V3.13675ZM10.2918 3.45728L10.0693 3.58136L5.41901 6.30073L3.33219 5.10131L3.7665 4.84282L8.10963 2.28888L10.2918 3.45728ZM0.948765 3.68476L1.43604 3.42626L5.81095 1.0481C5.81095 1.0481 5.81095 1.0481 5.8957 1.0481L7.12448 1.72019L7.43168 1.89597L2.67542 4.68772C2.13518 4.36719 1.52079 4.03631 0.948765 3.68476V3.68476ZM4.59276 11.4603L0.641568 9.12351C0.63572 9.09972 0.63572 9.07492 0.641568 9.05113V4.27413L5.01648 6.8901V11.7085L4.59276 11.4603Z"
                                fill="#285578"
                              />
                              <path
                                d="M14.3489 13.2073L12.6222 11.2635C13.0172 10.4583 13.0742 9.53443 12.7811 8.68884C12.4979 7.8382 11.8808 7.13179 11.0651 6.72427C10.6653 6.53855 10.2312 6.43366 9.78883 6.41589C9.34647 6.39812 8.905 6.46785 8.49095 6.62088C7.82769 6.85789 7.25719 7.29181 6.85962 7.86165C6.45492 8.4047 6.22284 9.05196 6.19226 9.72282V9.72282C6.19746 9.79166 6.19746 9.86078 6.19226 9.92962C6.19226 10.0847 6.19226 10.2398 6.25582 10.3846C6.39455 11.1527 6.81194 11.8464 7.43165 12.3388C7.90862 12.7474 8.49699 13.0119 9.12515 13.1C9.75332 13.1881 10.3942 13.0961 10.9697 12.8351H11.0651C11.0981 12.8617 11.1267 12.8931 11.1498 12.9282L12.7282 14.7066C12.8019 14.794 12.894 14.865 12.9981 14.915C13.1022 14.9649 13.216 14.9926 13.332 14.9961C13.5277 14.989 13.7149 14.9159 13.8616 14.7893L14.1476 14.5619L14.2324 14.4791C14.3326 14.408 14.416 14.3167 14.4771 14.2115C14.5382 14.1063 14.5755 13.9895 14.5865 13.8691C14.5975 13.7486 14.5821 13.6272 14.5411 13.5131C14.5001 13.399 14.4346 13.2947 14.3489 13.2073V13.2073ZM10.6096 12.2457C10.1226 12.4511 9.5826 12.5053 9.06298 12.4008C8.53369 12.296 8.04965 12.0363 7.67529 11.6564C7.29498 11.2694 7.03837 10.7827 6.93679 10.2557C6.8352 9.72872 6.89305 9.18426 7.10326 8.68884C7.30828 8.20005 7.65418 7.77981 8.09901 7.47908C8.53067 7.20395 9.03472 7.05671 9.55026 7.05515C9.90568 7.0517 10.2579 7.12073 10.5844 7.25783C10.911 7.39493 11.2046 7.59707 11.4464 7.85131C11.9456 8.34865 12.2268 9.01621 12.2303 9.71248V9.71248C12.236 10.2455 12.0773 10.7679 11.7748 11.2118C11.5033 11.6642 11.0968 12.0249 10.6096 12.2457V12.2457ZM11.6689 12.4939V12.4215C11.8932 12.249 12.0959 12.0511 12.2727 11.8321L12.4316 12.0286L11.8384 12.618L11.6689 12.4939ZM13.7875 13.9725L13.4167 14.2723C13.3426 14.3447 13.3002 14.3447 13.2366 14.2723L12.2303 13.135L12.6752 12.711L12.8341 12.5456L12.9188 12.6387L13.8087 13.6726C13.9464 13.776 13.9358 13.8588 13.7875 13.9725Z"
                                fill="#285578"
                              />
                              <path
                                d="M8.74533 8.49274H8.08856V11.0157H8.74533V8.49274Z"
                                fill="#285578"
                              />
                              <path
                                d="M9.91058 8.49274H9.25381V11.0157H9.91058V8.49274Z"
                                fill="#285578"
                              />
                              <path
                                d="M11.0864 8.49274H10.4191V11.0157H11.0864V8.49274Z"
                                fill="#285578"
                              />
                              <path
                                d="M8.04633 8.49274H7.62262V11.0157H8.04633V8.49274Z"
                                fill="#285578"
                              />
                              <path
                                d="M9.2113 8.49274H8.78757V11.0157H9.2113V8.49274Z"
                                fill="#285578"
                              />
                              <path
                                d="M10.3872 8.48212H9.95291V11.0154H10.3872V8.48212Z"
                                fill="#285578"
                              />
                              <path
                                d="M11.5526 8.48212H11.1183V11.0154H11.5526V8.48212Z"
                                fill="#285578"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="telephone-number" style={{ marginBottom: 4 }}>
                        <span className="telephone-image">
                          <img src={Phone} width="18" height="18" />
                        </span>
                        <span>
                          <strong>
                            <a href="tel:+370 690 00222">+370 690 00222</a>
                          </strong>
                        </span>
                      </div>
                    </Col>

                    <Col span={15} style={{ textAlign: "end" }}>
                      <Space size="small">
                        <div style={{ minWidth: 155 }}>
                          <Menu mode="horizontal" selectable={false}>
                            <Menu.Item
                              key="paslaugos"
                              onClick={() => {
                                setShowDrawer(!showDrawer)
                                setShowSearch(false)
                              }}
                              style={{ padding: 0, paddingRight: 8 }}
                            >
                              <div className="top-bar-link">{"Paslaugos"}</div>
                            </Menu.Item>
                            <div style={{ marginRight: 8 }}>
                              <a className="top-bar-link" href="/campaigns">
                                {"Pasiūlymai"}
                              </a>
                            </div>
                            <SubMenu
                              key="pagalba"
                              title={
                                <>
                                  <div className="top-bar-link">
                                    {"Pagalba"}
                                  </div>
                                </>
                              }
                              style={{
                                padding: 0,
                                paddingRight: 5,
                                paddingLeft: 5,
                              }}
                            >
                              <Menu.Item key="pagalba:1">
                                <a href="/duk">{"D.U.K"}</a>
                              </Menu.Item>
                              <Menu.Item key="pagalba:2">
                                <a href="/terms">{"Terminai ir sąlygos"}</a>
                              </Menu.Item>
                              <Menu.Item key="pagalba:3">
                                <a href="/terms_couriers_pages/privatumo-politika">{"Privatumo Politika"}</a>
                              </Menu.Item>
                            </SubMenu>
                          </Menu>
                        </div>
                        <div style={{ marginRight: 8 }}>
                          <a className="top-bar-link" href="/verslui">
                            {"Verslui"}
                          </a>
                        </div>
                        <div style={{ marginRight: 8 }}>
                          <a className="top-bar-link" href="/integracijos">
                            {"Integracijos"}
                          </a>
                        </div>
                        {/* <div>
                          <a className="top-bar-link" href="/how_it_works">
                            {"Kaip tai veikia?"}
                          </a>
                        </div> */}
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowSearch(!showSearch)
                          }}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.9579 12.9937L9.41321 8.44896C10.1185 7.53722 10.5 6.42247 10.5 5.24998C10.5 3.84648 9.95221 2.53049 8.96171 1.53824C7.97121 0.545998 6.65172 0 5.24998 0C3.84823 0 2.52874 0.547748 1.53824 1.53824C0.545998 2.52874 0 3.84648 0 5.24998C0 6.65172 0.547748 7.97121 1.53824 8.96171C2.52874 9.95396 3.84648 10.5 5.24998 10.5C6.42247 10.5 7.53547 10.1185 8.44721 9.41496L12.9919 13.9579C13.0053 13.9713 13.0211 13.9819 13.0385 13.9891C13.0559 13.9963 13.0746 14 13.0934 14C13.1123 14 13.131 13.9963 13.1484 13.9891C13.1658 13.9819 13.1816 13.9713 13.1949 13.9579L13.9579 13.1967C13.9713 13.1834 13.9819 13.1675 13.9891 13.1501C13.9963 13.1327 14 13.114 14 13.0952C14 13.0763 13.9963 13.0577 13.9891 13.0403C13.9819 13.0228 13.9713 13.007 13.9579 12.9937ZM8.02197 8.02197C7.27997 8.76221 6.29647 9.16996 5.24998 9.16996C4.20348 9.16996 3.21999 8.76221 2.47799 8.02197C1.73774 7.27997 1.32999 6.29647 1.32999 5.24998C1.32999 4.20348 1.73774 3.21824 2.47799 2.47799C3.21999 1.73774 4.20348 1.32999 5.24998 1.32999C6.29647 1.32999 7.28172 1.73599 8.02197 2.47799C8.76221 3.21999 9.16996 4.20348 9.16996 5.24998C9.16996 6.29647 8.76221 7.28172 8.02197 8.02197Z"
                              fill="#ACACAC"
                            />
                          </svg>
                        </div>

                        {!topBarData.data.user_signed_in ? (
                          <button
                            className="log-in-button"
                            onClick={() => {
                              window.location.href = "/users/sign_in"
                            }}
                          >
                            {"Prisijungti"}
                          </button>
                        ) : (
                          <Space size="large">
                            {topBarData.data.open_shipments_count && (
                              <div>
                                <a href={"/order_steps/payments"}>
                                  <Badge
                                    count={topBarData.data.open_shipments_count}
                                  >
                                    <ShoppingCartOutlined
                                      style={{
                                        fontSize: "24px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Badge>
                                </a>
                              </div>
                            )}

                            <button
                              className="log-in-button"
                              style={{ width: 180 }}
                              onClick={() => {
                                if (topBarData.data.contractor)
                                  window.location.href = "/orders"
                                else window.location.href = "/carts"
                              }}
                            >
                              {"Mano užsakymai"}
                            </button>

                            <div style={{ cursor: "pointer" }}>
                              <Dropdown
                                overlay={() => (
                                  <Menu>
                                    <Menu.Item>
                                      <a
                                        rel="noopener noreferrer"
                                        href="/users/edit"
                                      >
                                        <SettingFilled />
                                        {" Nustatymai"}
                                      </a>
                                    </Menu.Item>

                                    <Menu.Item>
                                      <a
                                        rel="noopener noreferrer"
                                        // href="users/sign_out"
                                        // data-method="delete"
                                        onClick={() => {
                                          Request(
                                            "../users/sign_out",
                                            "DELETE"
                                          ).then(() => {
                                            window.location.href = "/"
                                          })
                                        }}
                                      >
                                        <LogoutOutlined />
                                        {" Atsijungti"}
                                      </a>
                                    </Menu.Item>
                                  </Menu>
                                )}
                                placement="bottomRight"
                              >
                                <Space>
                                  <div
                                    style={{
                                      maxWidth: "100px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                    }}
                                  // className="overflow-hide-scrollbars overflow-hide-scrollbars-other"
                                  >
                                    {topBarData.data.user_name}
                                  </div>
                                  <svg
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.5005 4.5C9.75881 4.5 9.03379 4.71993 8.4171 5.13199C7.80042 5.54404 7.31977 6.12971 7.03594 6.81494C6.75211 7.50016 6.67785 8.25416 6.82255 8.98159C6.96724 9.70902 7.32439 10.3772 7.84884 10.9016C8.37329 11.4261 9.04147 11.7832 9.7689 11.9279C10.4963 12.0726 11.2503 11.9984 11.9356 11.7145C12.6208 11.4307 13.2064 10.9501 13.6185 10.3334C14.0306 9.7167 14.2505 8.99168 14.2505 8.25C14.2505 7.25544 13.8554 6.30161 13.1521 5.59835C12.4489 4.89509 11.4951 4.5 10.5005 4.5Z"
                                      fill="#285578"
                                    />
                                    <path
                                      d="M10.5 0C8.4233 0 6.39323 0.615814 4.66652 1.76957C2.9398 2.92332 1.59399 4.5632 0.799269 6.48182C0.00454947 8.40045 -0.203385 10.5116 0.201759 12.5484C0.606904 14.5852 1.60693 16.4562 3.07538 17.9246C4.54383 19.3931 6.41475 20.3931 8.45156 20.7982C10.4884 21.2034 12.5996 20.9954 14.5182 20.2007C16.4368 19.406 18.0767 18.0602 19.2304 16.3335C20.3842 14.6068 21 12.5767 21 10.5C20.9968 7.7162 19.8896 5.04733 17.9211 3.07889C15.9527 1.11044 13.2838 0.00317604 10.5 0ZM16.494 17.1945C16.4791 16.2107 16.0782 15.2722 15.3779 14.5812C14.6776 13.8901 13.7339 13.5019 12.75 13.5H8.25C7.26614 13.5019 6.32238 13.8901 5.62207 14.5812C4.92176 15.2722 4.52095 16.2107 4.506 17.1945C3.14593 15.9801 2.1868 14.3812 1.75563 12.6095C1.32446 10.8378 1.44158 8.97701 2.09148 7.27339C2.74139 5.56978 3.89342 4.10375 5.39503 3.06942C6.89665 2.0351 8.67701 1.48127 10.5004 1.48127C12.3237 1.48127 14.1041 2.0351 15.6057 3.06942C17.1073 4.10375 18.2594 5.56978 18.9093 7.27339C19.5592 8.97701 19.6763 10.8378 19.2451 12.6095C18.814 14.3812 17.8548 15.9801 16.4948 17.1945H16.494Z"
                                      fill="#285578"
                                    />
                                  </svg>
                                  <svg
                                    width="6"
                                    height="21"
                                    viewBox="0 0 6 4"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.87626 0.541161L3.40848 3.77792C3.17438 4.07403 2.81348 4.07403 2.58914 3.77792L0.121361 0.541161C-0.112736 0.234844 0.00431261 0 0.365213 0H5.63241C6.00306 0 6.11036 0.234844 5.87626 0.541161Z"
                                      fill="#285578"
                                    />
                                  </svg>
                                </Space>
                              </Dropdown>
                            </div>
                          </Space>
                        )}
                        <Translate />
                      </Space>
                    </Col>
                  </Row>
                </div>
                {showSearch && (
                  <div
                    style={{
                      boxShadow: "0px 8px 40px rgba(24, 40, 96, 0.12)",
                      position: "relative",
                      zIndex: -1,
                    }}
                  >
                    <Row
                      align="middle"
                      style={{
                        height: "50px",
                        backgroundColor: "#F4F6FA",
                        borderBottom: "solid 2px ghostwhite",
                      }}
                    >
                      <Col offset={2} span={19}>
                        <Search
                          placeholder="Įveskite paieškos raktažodžius"
                          size="middle"
                          style={{ width: "100%" }}
                          onSearch={(value) => {
                            window.location.href = `/search?search%5Bq%5D=${value}&button=`
                          }}
                        />
                      </Col>
                      <Col>
                        <div
                          style={{
                            cursor: "pointer",
                            paddingLeft: "5px",
                            marginTop: "5px",
                          }}
                          onClick={() => {
                            setShowSearch(false)
                          }}
                        >
                          {/* <CloseOutlined style={{ fontSize: "20px" }} /> */}
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.7141 1.23798C13.9209 1.44478 14.037 1.72526 14.037 2.01772C14.037 2.31019 13.9209 2.59067 13.7141 2.79747L9.03557 7.47595L13.714 12.1544C13.9209 12.3612 14.037 12.6417 14.037 12.9342C14.037 13.2266 13.9209 13.5071 13.7141 13.7139C13.5072 13.9207 13.2268 14.0369 12.9343 14.0369C12.6418 14.0369 12.3614 13.9207 12.1546 13.7139L7.47607 9.03545L2.79759 13.7139C2.59079 13.9207 2.31031 14.0369 2.01784 14.0369C1.72538 14.0369 1.4449 13.9207 1.2381 13.7139C1.0313 13.5071 0.915116 13.2266 0.915116 12.9342C0.915116 12.6417 1.0313 12.3612 1.2381 12.1544L5.91658 7.47595L1.2381 2.79747C1.0313 2.59067 0.915116 2.31019 0.915116 2.01772C0.915116 1.72526 1.0313 1.44478 1.2381 1.23798C1.4449 1.03117 1.72538 0.914995 2.01785 0.914994C2.31031 0.914995 2.59079 1.03117 2.79759 1.23798L7.47607 5.91646L12.1546 1.23798C12.3614 1.03117 12.6418 0.914995 12.9343 0.914994C13.2268 0.914995 13.5072 1.03117 13.7141 1.23798Z"
                              fill="#ACACAC"
                            />
                          </svg>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  height: 69,
                  background: "#FFFFFF",
                  borderBottom: "1px solid #EDEDED",
                  boxSizing: "border-box",
                  display: "flex",
                }}
              >
                <Row gutter={32} style={{ width: "100%" }} align="middle">
                  <Col offset={0} span={5}>
                    <img
                      src={s24logo}
                      style={{ width: 175, marginRight: 20, marginLeft: 20 }}
                    />
                  </Col>

                  <Col span={19} style={{ textAlign: "end", display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <a href="tel:+370 690 00222" >
                      <span style={{ paddingInline: 16 }}>
                        <img src={MobileToolbarPhone} width="38" height="38" />
                      </span>
                    </a>
                    <div style={{
                      marginRight: 8,
                    }}>
                      <Translate />
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setMobileExpand(!mobileExpand)
                      }}
                    >
                      {!mobileExpand ? (
                        <MenuOutlined style={{ fontSize: 24 }} />
                      ) : (
                        <CloseOutlined style={{ fontSize: 24 }} />
                      )}
                    </div>
                  </Col>
                </Row>

                <Drawer
                  title="Menu"
                  placement="left"
                  onClose={() => setMobileExpand(false)}
                  width="100%"
                  visible={mobileExpand}
                >
                  <div>
                    <Search
                      placeholder="Siuntų paieška"
                      size="large"
                      style={{
                        width: "100%",
                        maxWidth: 500,
                        backgroundColor: "#F4F6FA",
                      }}
                      onSearch={(value) => {
                        window.location.href = `/tracking?tracking%5Btracking_number%5D=${value}`
                      }}
                    />
                  </div>


                  <div className="telephone-number" style={{ display: "flex", justifyContent: "start", alignItems: "center", paddingTop: 25, paddingBottom: 20 }}>
                    <span className="telephone-image" style={{ paddingBottom: 4 }}>
                      <img src={Phone} width="12" height="12" />
                    </span>
                    <span>
                      <strong>
                        <a href="tel:+370 690 00222">+370 690 00222</a>
                      </strong>
                    </span>
                  </div>
                  <div>
                    <a className="top-bar-link" href="/duk">
                      {"D.U.K"}
                    </a>
                  </div>
                  <div>
                    <a className="top-bar-link" href="/campaigns">
                      {"Pasiūlymai"}
                    </a>
                  </div>
                  <div>
                    <a className="top-bar-link" href="/terms">
                      {"Terminai ir sąlygos"}
                    </a>
                  </div>
                  <div>
                    <a
                      className="top-bar-link"
                      href="/terms_couriers_pages/privatumo-politika"
                    >
                      {"Privatumo Politika"}
                    </a>
                  </div>

                  {/* <div>
                    <a className="top-bar-link" href="/how_it_works">
                      {"Kaip tai veikia?"}
                    </a>
                  </div> */}

                  <div>
                    <Search
                      placeholder="Įveskite paieškos raktažodžius"
                      size="large"
                      style={{ width: "100%", maxWidth: 500 }}
                      onSearch={(value) => {
                        window.location.href = `/search?search%5Bq%5D=${value}&button=`
                      }}
                    />
                  </div>

                  {!topBarData.data.user_signed_in ? (
                    <div>
                      <Divider />
                      <a className="top-bar-link" href="/users/sign_in">
                        <LoginOutlined />
                        {" Prisijungti"}
                      </a>
                      <Divider />
                    </div>
                  ) : (
                    <div>
                      {topBarData.data.open_shipments_count && (
                        <div>
                          <a
                            className="top-bar-link"
                            href={"/order_steps/payments"}
                          >
                            {`Cart (${topBarData.data.open_shipments_count})`}
                          </a>
                        </div>
                      )}

                      <div>
                        <a
                          className="top-bar-link"
                          href={
                            topBarData.data.contractor ? "/orders" : "/carts"
                          }
                        >
                          {"Mano užsakymai"}
                        </a>
                      </div>
                      <Divider />
                      <div>{topBarData.data.user_name}</div>
                      <div>
                        <a
                          className="top-bar-link"
                          rel="noopener noreferrer"
                          href="/users/edit"
                        >
                          <SettingFilled />
                          {" Nustatymai"}
                        </a>
                      </div>
                      <div>
                        <a
                          className="top-bar-link"
                          rel="noopener noreferrer"
                          href="../users/sign_out"
                          data-method="delete"
                        >
                          <LogoutOutlined />
                          {" Atsijungti"}
                        </a>
                      </div>
                      <Divider />
                    </div>
                  )}
                  <div className="verslui-top-bar">
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z">
                      </path>
                    </svg>
                    <a className="verslui-text" href="../verslui">
                      {"Verslui"}
                    </a>
                  </div>
                  <Collapse ghost>
                    <Panel header="Kurjeriai" key="1">
                      {topBarData?.data.service_pages_couriers.map((item) => (
                        <a
                          key={item.link}
                          href={`/terms_couriers_pages/${item.link}`}
                          style={{ color: "3e3e3e !important" }}
                          className="drawer-link-style"
                        >
                          {item.title}
                        </a>
                      ))}
                    </Panel>
                    <Panel header="Siuntų siuntimas" key="2">
                      <a className="drawer-link-style" href="/siuntu_tarnybos_paslaugos">
                        {"Teikiamos paslaugos"}
                      </a>
                      {topBarData?.data.service_pages_parcel_sending.map(
                        (item) => (
                          <a
                            key={item.link}
                            href={`/terms_couriers_pages/${item.link}`}
                            className="drawer-link-style"
                          >
                            {item.title}
                          </a>
                        )
                      )}
                    </Panel>
                    <Panel header="Pagalba" key="3">
                      {topBarData?.data.service_pages_help.map((item) => (
                        <a
                          key={item.link}
                          href={`/terms_couriers_pages/${item.link}`}
                          className="drawer-link-style"
                        >
                          {item.title}
                        </a>
                      ))}
                      {/* <a href={"/verslui"} className="drawer-link-style">
                        {"Verslui"}
                      </a> */}
                    </Panel>
                    <Panel header="Įrankiai" key="4">
                      <a
                        className="drawer-link-style"
                        href="/blogs"
                      >
                        {"Naujienos"}
                      </a>
                      {topBarData?.data.service_pages_tools.map((item) => (
                        <a
                          key={item.link}
                          href={`/terms_couriers_pages/${item.link}`}
                          className="drawer-link-style"
                        >
                          {item.title}
                        </a>
                      ))}
                    </Panel>
                  </Collapse>
                </Drawer>
              </div>
            </div>
          )
          }
        </div >
      )}
    </>
  )
}

export default TopBar
