import React, { useState, useEffect } from "react"

import { Row, Col, Input, Switch, Select, Space } from "antd"
import BulletPointTicket from "./components/BulletPointTick"
import integration from "images/landing-page/integration-image.png"
import Aos from "aos"
import "aos/dist/aos.css"
import { useMediaQuery } from "react-responsive"

const bulletPoints = [
  "API integracija prekiaujantiems internetu",
  "Galimybė sujungti ETSY, EBAY ar AMAZON paskyras su Siųsk24 paskyra",
  "Tinkama tokioms platformoms kaip SHOPIFY, WOOCOMMERCE, MAGENTO",
  "Efektyvus užsakymų valdymas vienoje platformoje",
]

const IntegrationSection = () => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 992px)" })

  return (
    <>
      <Row
        style={{ marginTop: isMobileScreen ? "20px" : "150px" }}
        gutter={{ lg: 16 }}
        align="middle"
      >
        <Col span={24} order={1} lg={{ offset: 2, span: 10, order: 0 }}>
          <div
            style={{
              display: "flex",
              justifyContent: isMobileScreen ? "center" : "end",
            }}
          >
            <div
              data-aos={isMobileScreen ? "" : "fade-right"}
              style={{ marginRight: !isMobileScreen && 130 }}
            >
              <img
                src={integration}
                style={{
                  width: "100%",
                  maxWidth: "567px",
                  marginLeft: isMobileScreen && "-10%",
                }}
              />
            </div>
          </div>
        </Col>

        <Col span={24} lg={10}>
          <div
            data-aos={isMobileScreen ? "" : "flip-left"}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div style={{ maxWidth: 600 }}>
              <div className="section-title">
                {"Integracijos elektroninėms parduotuvėms!"}
              </div>

              <div
                className="section-sub-title"
                style={{ marginTop: "32px", marginBottom: "5px" }}
              >
                {
                  "Sąsaja su elektroninėmis parduotuvėmis ir prekybos platformomis"
                }
              </div>

              {bulletPoints.map((point, index) => (
                <div className="section-bullet-point" key={index}>
                  <Space size="small" style={{ alignItems: "center" }}>
                    <BulletPointTicket />
                    <div>{point}</div>
                  </Space>
                </div>
              ))}

              <a href="/terms_couriers_pages/api-integracija">
                <button
                  className="calculate-price-button"
                  style={{ position: "unset", marginTop: 47 }}
                >
                  {"Sužinoti daugiau"}
                </button>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default IntegrationSection
