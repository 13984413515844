require.context("images/country_flags", false, /\.(png|jpe?g|svg)$/)

const importAll = (r) => {
  let images = {}
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item)
  })
  return images
}

export const COUNTRY_FLAGS = importAll(
  require.context("images/country_flags", false, /\.(png|jpe?g|svg)$/)
)
